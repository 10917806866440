// Vendor imports
import { Fancybox } from "@fancyapps/ui"
import Alpine from "alpinejs"
import collapse from "@alpinejs/collapse"
import focus from "@alpinejs/focus"
import intersect from "@alpinejs/intersect"
Alpine.plugin(intersect)
Alpine.plugin(focus)
Alpine.plugin(collapse)
window.Alpine = Alpine
window.Alpine.start()

import "lazysizes/plugins/respimg/ls.respimg"
import "lazysizes/plugins/parent-fit/ls.parent-fit"
import "lazysizes"
import "lazysizes/plugins/object-fit/ls.object-fit"

// Module imports
import "./modules/svgIconSprite"
import "./modules/alerts"
import "./modules/filters"
import "./modules/carousel"
