import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

var featureCarousel = new Swiper(".feature-carousel", {
  slidesPerView: 1.5,
  centeredSlides: true,
  spaceBetween: 16,
  loop: true,
  navigation: {
    prevEl: ".swiper-prev",
    nextEl: ".swiper-next",
  },
  breakpoints: {
    1280: {
      slidesPerView: 2,
      spaceBetween: 16,
    },
    2160: {
      slidesPerView: 3,
      spaceBetween: 16,
    },
  },
});
